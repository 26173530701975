<template>
	<w-dialog
		:max-width="$vuetify.breakpoint.mdAndUp ? '50vw' : null"
		:title="action == 'create' ? $t('offers.create_offer') : $t('offers.update_offer')"
		:value="opened"
		@input="closeDialog()"
	>
		<v-stepper v-model="step">
			<v-stepper-header>
				<template v-for="n in steps">
					<v-stepper-step :key="`${n}-step`" :class="{ pointer: step > n }" :complete="step > n" :step="n" @click="move(n)"></v-stepper-step>
					<v-divider v-if="n < steps" :key="n" />
				</template>
			</v-stepper-header>
			<v-stepper-content step="1">
				<w-layout align-center pl-4 row wrap>
					<w-flex px-1 xs12>
						<v-text-field
							ref="offerTitle"
							v-model="offer.name"
							:label="$t('name') + '*'"
							maxlength="191"
							type="text"
							@keyup.enter="isFirstStepValid ? (step = 2) : (step = 1)"
						/>
					</w-flex>
					<w-flex :class="offer.duration_id ? '' : 'pt-1'" px-1 xs6>
						<w-currency-input v-model="offer.price" :label="$t('price') + '*'" />
					</w-flex>
					<w-flex :class="offer.duration_id ? '' : 'pt-1'" px-1 xs6>
						<v-select v-model="offer.duration_id" :items="durations" item-text="title" item-value="id" :label="$t('duration')" />
					</w-flex>
				</w-layout>
				<w-layout pl-3>
					<w-flex>
						<div :label="$t('description')">
							<w-rich-text-editor v-model="offer.description" style="max-height: 300px; overflow-y: scroll"></w-rich-text-editor>
						</div>
					</w-flex>
				</w-layout>
			</v-stepper-content>
			<v-stepper-content step="2">
				<w-layout v-for="(catalogService, index) in offer.catalog_services" :key="index" align-center row>
					<w-flex :class="catalogService.id ? '' : 'pt-1'" grow pr-1>
						<v-select
							v-model="catalogService.id"
							:error-messages="getErrorMessage(catalogService)"
							:items="catalogServices"
							item-text="title"
							item-value="id"
							:label="$t('offers.services')"
						/>
					</w-flex>
					<w-flex shrink px-1>
						<v-text-field
							v-model="catalogService.quantity"
							color="primary"
							:label="$t('quantity')"
							min="1"
							style="width: 75px"
							type="number"
							@keyup="onKeyUpOnQuantityInput(index)"
						/>
					</w-flex>
					<w-flex shrink pl-1>
						<w-btn :disabled="offer.catalog_services.length < 2" flat icon="delete" mini @click="deleteService(index)"> </w-btn>
					</w-flex>
				</w-layout>
				<w-layout justify-end>
					<w-btn @click="addRow">{{ $t('offers.add_service') }}</w-btn>
				</w-layout>
			</v-stepper-content>
		</v-stepper>
		<template v-slot:actions>
			<v-layout justify-end>
				<w-btn v-if="step == 1" :disabled="!isFirstStepValid" flat @click="step = 2">{{ $t('next') }}</w-btn>
				<w-btn v-else :disabled="!isSecondStepValid" flat @click="method">{{ $t('actions.save') }}</w-btn>
			</v-layout>
		</template>
	</w-dialog>
</template>

<script>
import OffersModuleGuard from '@/mixins/ModulesGuards/Offers/OffersModuleGuard'

export default {
	name: 'EditCatalogOffer',
	mixins: [OffersModuleGuard],
	props: {
		action: {
			default: () => 'create',
			required: false,
			type: String
		},
		opened: {
			default: () => false,
			required: false,
			type: Boolean
		},
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			catalogServices: [],
			durations: [],
			offer: {},
			step: 1,
			steps: 2
		}
	},
	computed: {
		isFirstStepValid: function () {
			return !!this.offer.name && this.offer.price
		},
		isSecondStepValid: function () {
			let result = null
			const catalogServices = this.offer.catalog_services
			if (!catalogServices || !Array.isArray(catalogServices)) {
				result = false
			} else {
				result = catalogServices.every(item => !isNaN(item.id) && item.quantity > 0)
			}
			return result
		},
		method: function () {
			let result = null
			if (this.action === 'create') {
				result = this.save
			} else {
				result = this.update
			}
			return result
		}
	},
	watch: {
		opened: {
			handler: function (val) {
				if (val) {
					this.step = 1
					if (this.action == 'create') {
						this.offer = { catalog_services: [{ quantity: 1 }] }
					} else {
						this.offer = { ...this.value }
						if (this.value.catalog_services) {
							this.offer.catalog_services = [
								...this.value.catalog_services.map(cs => {
									cs.quantity = cs.pivot.quantity ?? 1
									delete cs.pivot
									return cs
								})
							]
						} else {
							this.offer.catalog_services = []
						}
					}
					this.$nextTick(() => {
						this.$refs.offerTitle.focus()
					})
				}
			}
		}
	},
	created: function () {
		this.getDurations()
		this.listCatalogServices()
	},
	methods: {
		addRow: function () {
			if (!this.offer.catalog_services) {
				this.offer.catalog_services = []
			}
			this.offer.catalog_services.push({ quantity: 1 })
		},
		closeDialog: function () {
			this.$emit('close')
		},
		deleteService: function (index) {
			this.offer.catalog_services.splice(index, 1)
		},
		getDurations: function () {
			this.service.listDurations().then(durations => {
				this.durations = durations
			})
		},
		getErrorMessage: function (service) {
			let result = []
			if (!service.id) {
				result.push(this.$t('offers.errors.no_service_selected'))
			}
			return result
		},
		listCatalogServices: function () {
			this.service.listCatalogServices(this.accountingFirmId).then(res => {
				this.catalogServices = res
			})
		},
		move: function (i) {
			if (i < this.step) {
				this.step = i
			}
		},
		onKeyUpOnQuantityInput: function (index) {
			const quantity = this.offer.catalog_services[index].quantity
			if (isNaN(quantity) || quantity < 1) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('offers.errors.negative_quantity'))
			}
		},
		save: function () {
			this.offer.catalog_services = this.offer.catalog_services.filter(item => item.id)
			this.service.createCatalogOffer(this.accountingFirmId, this.offer).then(res => {
				this.$emit('addOffer', res)
				this.closeDialog()
			})
		},
		update: function () {
			this.service.updateCatalogOffer(this.accountingFirmId, this.value.id, this.offer).then(res => {
				this.$emit('updateOffer', res)
				this.closeDialog()
			})
		}
	}
}
</script>
